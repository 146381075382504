<template>
  <div
    class="bg-gray-50"
  >
    <div
      v-if="organisationLogo"
      class="p-6 text-center"
      :style="organisationStyle"
    >
      <img
        :src="organisationLogo"
        :alt="organisationName"
      />
    </div>
    <div
      v-if="haveExamsLoaded && hasTokenValidationAttempt"
      class="flex-grow h-screen max-w-screen-xl px-6 mx-auto bg-gray-50"
    >
      <div v-if="isTokenValid">
        <section class="pt-10 mx-auto text-center">
          <h1
            v-if="!examsComplete"
            class="mb-2 text-4xl"
          >
            Welcome {{ candidateName }}
          </h1>

          <p
            v-if="!examsComplete && exams.length > 0"
            class="text-2xl"
          >
            You have been invited to take the following tests by {{ organisationName }}
          </p>
          <p
            v-else-if="!examsComplete && exams.length === 0"
            class="text-2xl"
          >
            There are no pending tests for you to complete.
            When tests are available, they will appear here.
          </p>
          <p
            v-else-if="examsComplete"
            class="text-2xl"
          >
            Great, you’ve completed your tests
          </p>
        </section>
        <section
          v-if="exams.length > 0"
          class="py-10"
        >
          <div v-if="!examsComplete">
            <div class="grid gap-8 md:grid-cols-2">
              <LaunchAssessment
                v-for="exam in exams"
                :key="exam.slug"
                :content="exam"
                :query="query"
                :custom-button-colour="organisationColour"
                :timer-disabled="organisationUsesUnlimitedTimeExams"
              />
            </div>
          </div>
          <div v-else-if="organisationCompletedAction">
            <p class="text-lg text-center">
              Next step from {{ organisationName }}
            </p>
            <p class="text-xl text-center">
              <a
                :href="organisationCompletedAction.url"
                class="underline text-secondary"
              >
                {{ organisationCompletedAction.label }}
              </a>
            </p>
          </div>
        </section>
      </div>
      <div
        v-else
        class="p-6 text-center"
      >
        <!-- @TODO @Ross 2019-11-27 this needs some love -->
        <!-- You can get here by chopping a character off the token in the URL -->
        <p>Sorry, the link you’ve used is missing something.</p>
        <p>Try getting the link from the email again.</p>
      </div>
    </div>
    <div
      v-else
      class="flex-grow my-12"
    >
      <Loader />
    </div>
  </div>
</template>

<script>
import LaunchAssessment from '@components/Assessments/LaunchAssessment'
import Loader from '@components/Loader'

import { mapGetters } from 'vuex'

export default {
  components: {
    LaunchAssessment,
    Loader
  },

  computed: {
    ...mapGetters({
      haveExamsLoaded: 'exams/haveExamsLoaded',
      exams: 'exams/examsByJobExamSlugs',
      hasTokenValidationAttempt: 'candidates/hasTokenValidationAttempt',
      candidatesAttempts: 'candidates/attempts',
      candidateName: 'candidates/candidateName',
      organisationName: 'candidates/organisationName',
      organisationLogo: 'candidates/organisationLogo',
      organisationColour: 'candidates/organisationColour',
      organisationUsesUnlimitedTimeExams: 'candidates/organisationUsesUnlimitedTimeExams',
      organisationCompletedAction: 'candidates/organisationCompletedAction',
      isTokenValid: 'candidates/isTokenValid'
    }),

    /**
     * @return {string}
     */
    organisationStyle() {
      return 'background-color: ' + this.organisationColour + ';'
    },

    /**
     * @return {string}
     */
    candidateId() {
      return this.$route.query.candidate
    },

    /**
     * @return {string}
     */
    jobId() {
      return this.$route.query.job
    },

    /**
     * @return {string}
     */
    sittingToken() {
      return this.$route.query.sitting
    },

    /**
     * @return {string}
     */
    token() {
      return this.$route.query.token
    },

    /**
     * To be passed to the next part of the journey
     *
     * @return {Object}
     */
    query() {
      return {
        candidate: this.candidateId,
        job: this.jobId,
        sitting: this.sittingToken,
        token: this.token
      }
    },

    /**
     * @return {Boolean}
     */
    examsComplete() {
      const attemptExamSlugs = this.candidatesAttempts.map(attempt => attempt.examSlug)
      const remainingExams = this.exams.filter(exam => {
        return !attemptExamSlugs.includes(exam.slug)
      })
      return remainingExams.length === 0
    }
  },

  created() {
    if (!this.haveExamsLoaded) {
      this.$store.dispatch('exams/getExams', this.token)
    }

    this.validateToken()
  },

  methods: {
    /**
     * Trigger a token validation
     */
    validateToken() {
      if (this.isTokenValid) {
        // Already valid
        return
      }

      if (!this.candidateId || !this.token) {
        console.error(
          'Something is missing',
          this.candidateId,
          this.token
        )
        // @TODO 2019-11-27 This error isn’t being caught by the handler to
        // show the user an error message
        throw new Error('Cannot find required query')
      }
      this.$store.dispatch('candidates/validateToken', {
        candidateId: this.candidateId,
        jobId: this.jobId,
        sittingToken: this.sittingToken,
        token: this.token
      })
    }
  }
}
</script>
